// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("trix")
// require("@rails/actiontext")

import "bootstrap" 
import "../stylesheets/application"

document.addEventListener("turbolinks:load", () => {
  // Trix.config.blockAttributes.default.tagName = "p"
  $('[data-toggle="tooltip"]').tooltip({container: 'body'});
  $('[data-toggle="popover"]').popover();
  // document.getElementById('treatment-description-collapsible').onclick = handleClick;
  $('#treatment-description-collapse').on('hidden.bs.collapse', function () {
    $('#treatment-description-collapsible').text('more...');
  });
  $('#treatment-description-collapse').on('shown.bs.collapse', function () {
    $('#treatment-description-collapsible').text('less');
  });
  $('#introduction-collapse').on('hidden.bs.collapse', function () {
    $('#introduction-collapsible').text('more...');
  });
  $('#introduction-collapse').on('shown.bs.collapse', function () {
    $('#introduction-collapsible').text('less');
  });
  CKEDITOR.replace('blog-body');

  $('.course').select2({
    ajax: {
      url: '/courses',
      dataType: 'json',
      delay: 250
    },
    debug: true,
    minimumInputLength: 1,
    allowClear: true,
    placeholder: "Select Course"
  });

  $('.specialization').select2({
    ajax: {
      url: '/specializations',
      dataType: 'json',
      delay: 250
    },
    debug: true,
    minimumInputLength: 2,
    allowClear: true,
    placeholder: "Select Specialization"
  });

  $('.institute').select2({
    ajax: {
      url: '/institutes',
      dataType: 'json',
      delay: 250
    },
    debug: true,
    minimumInputLength: 2,
    allowClear: true,
    placeholder: "Select Institute"
  });

  $('.university').select2({
    ajax: {
      url: '/universities',
      dataType: 'json',
      delay: 250
    },
    debug: true,
    minimumInputLength: 2,
    allowClear: true,
    placeholder: "Select University"
  });

  $('.treatment').select2({
    ajax: {
      url: '/treatments',
      dataType: 'json',
      delay: 250
    },
    debug: true,
    minimumInputLength: 2,
    allowClear: true,
    placeholder: "Select Treatment"
  });

  $('.organizations').select2({
    ajax: {
      url: '/organizations',
      dataType: 'json',
      delay: 250
    },
    multiple: true,
    debug: true,
    minimumInputLength: 2,
    allowClear: true,
    placeholder: "Select Organiations"
  });

  $('form').on('click', '.remove_fields', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').hide();
    return event.preventDefault();
  });

  $('form').on('click', '.add-fields', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));

    // What follows is a dirty implementation of Select2 on dynamically added fields
    // Not DRY
    // Refactor required here
    // Dirty implementation starts
    $('.course').select2({
      ajax: {
        url: '/courses',
        dataType: 'json',
        delay: 250
      },
      debug: true,
      minimumInputLength: 1,
      allowClear: true,
      placeholder: "Select Course"
    });

    $('.specialization').select2({
      ajax: {
        url: '/specializations',
        dataType: 'json',
        delay: 250
      },
      debug: true,
      minimumInputLength: 2,
      allowClear: true,
      placeholder: "Select Specialization"
    });

    $('.institute').select2({
      ajax: {
        url: '/institutes',
        dataType: 'json',
        delay: 250
      },
      debug: true,
      minimumInputLength: 2,
      allowClear: true,
      placeholder: "Select Institute"
    });

    $('.university').select2({
      ajax: {
        url: '/universities',
        dataType: 'json',
        delay: 250
      },
      debug: true,
      minimumInputLength: 2,
      allowClear: true,
      placeholder: "Select University"
    });
    
    $('.treatment').select2({
      ajax: {
        url: '/treatments',
        dataType: 'json',
        delay: 250
      },
      debug: true,
      minimumInputLength: 2,
      allowClear: true,
      placeholder: "Select Treatment"
    });
    // Dirty implementation ends.

    return event.preventDefault();
  });
});

document.addEventListener('turbolinks:load', function(event) {
  if (typeof gtag === 'function') {
    gtag('config', 'UA-88747345-1', {
      'page_title' : event.target.title,
      'page_location': event.data.url
    });
  }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)